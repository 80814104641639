
.home-header-nav{
  display: flex;
  justify-content: right;
  padding-right: 15vw;
  gap:1vw;
  align-items: center;
  margin-top: 4vh;
}
.home-header-nav p {
  font-size: $largeFontSize;
  font-weight: 200;
  font-weight: bold;
  margin: 0;
}
.home-header-nav button {
  font-weight: bold;
}

.home-download-wrapper{
  padding: 2vh 8vw;
}

.home-download{
  display: inline-block;
  font-size: $largeFontSize;
}

.home-body-wrapper{
  padding: 0 8vw 6vh 8vw;
}

.home-body-header{
  font-size: $largeFontSize;
  font-weight: bold;
}
.home-body-wrapper div{
  box-shadow: none;
}

.home-body-wrapper tbody{
  tr:last-child{
    td{
      border-bottom: none;
    }
  }
}

.home-body-wrapper td{
  // text-align: center;
  padding-left: 0;
}
.home-body-wrapper th{
  // text-align: center;
  font-weight: bold;
  padding:0;
}

.home-table-popout{
  display: flex;
  width: 200px;
  flex-direction: column;
  gap: 10px;
  padding: 30px 20px;
}

.home-footer{
  width: 100%;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 900px){
  .home-body-wrapper{
    margin: 20px;
    padding: 0;
  }
  .home-header-nav{
    justify-content: center;
    padding-right: 0;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
}

