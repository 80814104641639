.accordion-summary-wrapper {
 margin: 2vh 1vw;
 padding: 2vh 2vh 1vh 2vh;
 border: 1px solid grey;
 border-radius: 15px;
 position: relative;
}

@media only screen and (min-width:769px) {
 .accordion-summary-wrapper {
   margin: 2vh 14vw;
 }
}

.accordion-item{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mobile-accordion-table{
  width: 100%;
  border-collapse: collapse;
}

.mobile-accordion-table tr {
  border-bottom: grey solid 1px;
}

.mobile-accordion-table tr:last-child
{
   border-bottom: none;
}

.mobile-accordion-table td:nth-child(odd){
  text-align: left;
  padding: 1vh 0;
}

.mobile-accordion-table td:nth-child(even){
text-align: right;
padding: 1vh;
}

.mobile-accordion-close{
  position: absolute;
  cursor: pointer;
  right: 0;
}
