.presenter-container-sidebar-body{
  display: flex;
  align-items: center;
  justify-content: 'space-between';
  padding: '0 2vw';
  border-bottom: '1px solid #aaaaaa'
}

.presenter-controller-sidebar{
  border: 1px solid #BFBFBF;
  box-shadow: 0px 7px 10px #aaaaaa;

}
