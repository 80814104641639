
.modal-context{
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width: 34vw;
  padding: 7vh 3vw;
}

.modal-header{
  text-align: center;
  font-size: $largeFontSize;
  font-weight: bold;
}
.modal-textfields{
  display: flex;
  flex-direction: column;
}
.modal-submitbutton{
  color: white;
}
.modal-open-session-buttons{
  display: flex;
  justify-content: center;
  gap: 2vw;
}

@media only screen and (max-width: 900px) {

  .modal-context{
    width: 95vw;
  }
}
