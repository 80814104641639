

.sidebar-content-listitem {
  display: flex;
  padding: 0 30px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

