.regPage-wrapper{
  height: 100%;
  gap: 3vh;
}

.regPage-wrapper > *{
  width: 30%;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .regPage-wrapper > *{
    all: unset;
  }
  .error-text{
    color: red;
  }
}

