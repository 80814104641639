$fontSize: 17px;
$largeFontSize:22px;
$font-family: 'Avenir', 'Arial', sans-serif;
$font-family-bold: 'Avenir Bold', 'Arial Bold', sans-serif;
$font-family-medium: 'Avenir Bold', 'Arial Medium', sans-serif;

$Blue:#2C7CD5;
$Cream:#f5f6f6;

* {
  box-sizing: border-box;
  outline: none;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height:100%;
  margin: auto;
  overflow: auto;
  font-size: $fontSize;
}

body {
  margin: 0;
  font-family: $font-family;
  font-size: $fontSize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;

  .direction-column {
    flex-direction: column;
  }
}

.flex-center {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

.error-text{
  color:red;
}

.editButton:hover{
  color: grey !important;
  cursor: pointer;
}

@keyframes glow {
  0% {
    background-color:$Blue;
    box-shadow: 0 0 3px blue;
  }
  50% {
    background-color: $Blue;
    box-shadow: 0 0 10px blue;
  }
  100% {
    background-color: $Blue;
    box-shadow: 0 0 3px blue;
  }
}

.pulse-button{
  animation: glow 1300ms infinite;
}
@import './RegPage.module.scss';
@import './Home.module.scss';
@import './Modal.module.scss';
@import './Nav.module.scss';
@import './PresenterContoller.module.scss';
@import './MobileAccordion.module.scss';
@import './SideBar.module.scss';