.presenter-controller-body{
  flex:4;
  margin: 0 2vw;
}

.presenter-controller-innerbody{
  height: 100%;
}

.presenter-innerbody-topbox{
  position: relative;
  background-color: #f5f6f6;
  padding: 0 1vw 2vh 1vw;
  margin-bottom: 3vh;
}

.presenter-innerbody-header{
  padding-top:2vh;
}

.presenter-innerbody-header :first-child{
  text-align: center;
  font-size: 25px;
}
.presenter-innerbody-header :nth-child(2){
  font-size: 20px;
}

.polling-results-wrapper{
  display: flex;
  justify-content: center;
}

.presenter-controller-footer{
  margin-top: 4vh;
  float: right;
}

.controller-slide{
  background-color: #f5f6f6;
  width: 48.5%;
  padding: 3vh;
}

.sidebar-open-icon{
  position: absolute;
  top: 10px;
  right: 10px;
}

.play-and-pause-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  gap: 30px;

  svg{
    font-size: 60px;
  }
}

